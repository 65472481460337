import api from "./api2";

const noThumbnail = {
  src: "placeholder.svg",
  description: "add:north",
  bgColor: null,
};

export default class Variation {
  #data;
  #store;
  #quantity;
  #sale;
  #msrps = {};
  #stock = null;

  // Store properties
  id;
  sku;
  name;
  type;
  physical;
  productId;
  productName;
  manufacturer;
  gtin;
  discontinued;
  discount;
  msrp = 0;
  price = 0;
  subTotal = 0;
  totalDiscount = 0;
  vat = 0;
  availability = {
    totalAvailable: 0,
    maxQtyToOrder: 0,
    isBackOrder: false,
    canBeOrdered: false,
    deliveryDate: null,
    items: [],
    fetched: false,
  };

  // Visual properties
  images = [];
  assets = [];
  colorHex;
  pattern;
  thumbnail = noThumbnail;
  labels = {
    newProduct: false,
    bestSeller: false,
    staffPick: false,
    discount: 0,
  };
  bullets = [];
  texts = {
    primaryAttribute: "",
    secondaryAttribute: "",
    remainingAttributes: "",
    title: "",
  };
  categories = [];
  attributes = {};
  productLink = "";
  description = "";

  // Additional
  jsonld;
  googleCart;

  constructor(store, variation, quantity = 1, fetched = false) {
    if (fetched && variation.stock) {
      this.#stock = variation.stock;
      this.availability.fetched = true;
    }
    this.#quantity = quantity;
    this.#data = variation;
    this.#store = store;
    this.#newData(variation);
    store.watch(
      (state, getters) => [
        getters["account/agreements"],
        state.store.currency,
        state.store.noBackOrder,
        state.store.showWithVat,
        store.state.content.attributes,
        store.getters["content/displayAttribute"],
      ],
      () => {
        this.#onUpdate();
      }
    );
  }

  clone() {
    return new Variation(this.#store, this.#data, this.#quantity);
  }

  #onUpdate() {
    this.#discount();
    this.#totals();
    this.#availability();
    this.#jsonLd();
    this.#googleCartItem();
  }

  #newData(data) {
    this.#sale = data.sale;
    this.#msrps = data.msrp;
    this.#extract(data);
    this.#discount();
    this.#totals();
    this.#availability();
    this.#jsonLd();
    this.#googleCartItem();
  }

  #extract(data) {
    this.images = data.assets.filter((i) => i.type === "image");
    this.categories = data.categories;
    this.attributes = data.attributes || {};
    Object.entries(data.attributes).forEach(([attribute, value]) => {
      if (value === "msrp") {
        this.attributes[attribute] = `${(this.price + this.vat) / 100} ${
          this.#store.state.store.currency
        }`;
      }
    });
    if (this.attributes) this.sku = data.sku;
    this.id = data.id;
    this.name = data.name;
    this.productId = data.product.id;
    this.productName = data.product.name;
    this.manufacturer = data.product.manufacturer;
    this.gtin = data.gtin;
    this.productLink = `/product/${data.product.name}/${this.name}`;
    this.discontinued = data.discontinued || data.product.discontinued;
    this.colorHex = data.colorHex || null;
    this.pattern = data.assets.find(({ type }) => type === "pattern");
    this.type = data.type;
    this.physical = data.physical;
    // Labels
    // Latest if active last 30 days
    if (data.activeDate >= Date.now() - 30 * 24 * 60 * 60 * 1000) {
      this.labels.newProduct = true;
    }
    if (data.bestSeller > 0) {
      this.labels.bestSeller = true;
    }
    if (data.staffPick > 0) {
      this.labels.staffPick = true;
    }

    // Bullets
    if (
      data._snippetResult &&
      data._snippetResult.product &&
      data._snippetResult.product.bullets &&
      data._snippetResult.product.bullets.length
    ) {
      this.bullets.push(...data._snippetResult.product.bullets.map(({ value }) => value));
    } else if (data.product && data.product.bullets && data.product.bullets.length) {
      this.bullets.push(...data.product.bullets);
    }
    if (this.bullets.length > 3) this.bullets = this.bullets.slice(0, 3);
    // Texts
    const usedAttributes = [];
    const [primaryAttribute, primaryAttributeArr] = this.#labelReplacements(
      data,
      data.product.primaryAttribute
    );
    usedAttributes.push(...primaryAttributeArr);
    if (primaryAttribute) this.texts.primaryAttribute = primaryAttribute;
    const [secondaryAttribute, secondaryAttributeArr] = this.#labelReplacements(
      data,
      data.product.secondaryAttribute
    );
    usedAttributes.push(...secondaryAttributeArr);
    if (secondaryAttribute) this.texts.secondaryAttribute = secondaryAttribute;
    let remainingAttributes = "";
    const remainingAttributesArr = Object.keys(data.attributes || {})
      .map((i) => `attributes.${i}`)
      .filter((i) => !usedAttributes.includes(i));
    if (remainingAttributesArr.length && data.type === "filament") {
      [remainingAttributes] = this.#labelReplacements(
        data,
        remainingAttributesArr.map((attr) => `{{${attr}}}`).join(" - ")
      );
      if (remainingAttributes !== secondaryAttribute) {
        this.texts.remainingAttributes = remainingAttributes;
      }
    }
    if (data._highlightResult && data._highlightResult.name && data._highlightResult.name.value) {
      this.texts.title = data._highlightResult.name.value;
    } else if (primaryAttribute && secondaryAttribute) {
      this.texts.title = `${primaryAttribute} - ${secondaryAttribute}`;
    } else if (primaryAttribute && remainingAttributes) {
      this.texts.title = `${primaryAttribute} - ${remainingAttributes}`;
    } else if (primaryAttribute) {
      this.texts.title = primaryAttribute;
    } else if (secondaryAttribute) {
      this.texts.title = secondaryAttribute;
    }
    this.description = (data.description || "") + (data.product.description || "");
    // Thumbnail
    const thumbnail = this.images.find((i) => i.thumbnail);
    this.thumbnail =
      thumbnail && thumbnail.imgixPath
        ? {
            src: thumbnail.imgixPath,
            description: thumbnail.description,
            bgColor: thumbnail.bgColor,
          }
        : noThumbnail;
  }

  #discount() {
    this.discount = null;
    if (this.#sale) {
      this.discount = {
        type: "sale",
        name: this.#sale.name,
        discountPercentage: this.#sale.discountPercentage,
      };
    }
    this.#store.getters["account/agreements"].forEach(({ productId, discountPercentage }) => {
      if (
        productId === this.productId ||
        productId === this.productName ||
        (productId === "All" && this.type === "filament")
      ) {
        if (!this.discount || discountPercentage > this.discount.discountPercentage) {
          this.discount = {
            type: "agreement",
            name: "agreement",
            discountPercentage,
          };
        }
      }
    });
    if (this.discount && this.discount.discountPercentage > 0) {
      this.labels.discount = this.discount.discountPercentage;
    }
  }

  #totals() {
    this.msrp = this.#msrps[this.#store.state.store.currency];
    this.price = this.msrp;
    this.subTotal = this.msrp * this.#quantity;
    this.totalDiscount = 0;
    if (this.discount && this.discount.discountPercentage) {
      this.price = Math.round((this.msrp * this.discount.discountPercentage) / 100);
      this.totalDiscount = this.price * this.#quantity;
    }
    this.vat = this.#store.state.store.showWithVat
      ? Math.round((this.subTotal - this.totalDiscount) * 0.25)
      : 0;
  }

  async getAvailability() {
    try {
      const { data } = await api.get(`catalog/variations/${this.id}/stock`);
      this.#stock = data;
      this.availability.fetched = true;
      this.#availability();
    } catch (error) {
      console.error(error);
    }
  }

  #availability() {
    if (!this.availability.fetched) return;
    const { totalAvailable = 0, ETA = null, items = [] } = this.#stock;
    let maxQtyToOrder = 999;

    let allowedBackOrder = true;

    let globalDisallowBackorder = window.initialData.settings.settings.noBackOrder;

    if (![true, false].includes(globalDisallowBackorder)) {
      globalDisallowBackorder = true;
    }

    if (globalDisallowBackorder) allowedBackOrder = false;
    if (!this.#data.backOrder) allowedBackOrder = false;
    if (this.discontinued) allowedBackOrder = false;
    if (Boolean(this.#sale) && !this.#data.backOrder) allowedBackOrder = false;

    if (!allowedBackOrder) {
      maxQtyToOrder = totalAvailable;
    }

    let deliveryDate = ETA ? ETA.substring(0, 10) : null;
    let canBeOrdered = true;
    let isBackOrder = false;

    if (this.#quantity <= totalAvailable) {
    } else if (this.#quantity > maxQtyToOrder) {
      canBeOrdered = false;
    } else if (allowedBackOrder && ETA) {
      isBackOrder = true;
    } else {
      canBeOrdered = false;
    }
    Object.assign(this.availability, {
      totalAvailable,
      maxQtyToOrder,
      isBackOrder,
      canBeOrdered,
      deliveryDate,
      items,
    });
  }

  get quantity() {
    return this.#quantity;
  }

  set quantity(quantity) {
    this.#quantity = quantity;
    this.#onUpdate();
  }

  setQuantity(quantity) {
    this.#quantity = quantity;
    this.#onUpdate();
  }

  #labelReplacements(data, template) {
    const ex = /{{([\w\.]+)}}/;
    const usedAttributes = [];
    while (template.match(ex)) {
      const match = template.match(ex);
      template = template.replace(match[0], this.#extractDotNotation(data, match[1]) || "");
      usedAttributes.push(match[1]);
    }
    return [template, usedAttributes];
  }

  #extractDotNotation(data, key) {
    if (key === "attributes.giftCardValue") return (this.price + this.vat) / 100;
    if (key === "currency") return this.#store.state.store.currency;
    let o = { ...data };
    key = key.replace(/\[(\w+)\]/g, ".$1");
    key = key.replace(/^\./, "");
    const a = key.split(".");
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return this.#store.getters["content/displayAttribute"](a[a.length - 1], o);
  }

  #jsonLd() {
    const jsonld = {
      "@context": "http://schema.org/",
      "@type": "Product",
      name: this.name,
      description: this.bullets.join(". "),
      brand: {
        "@type": "Brand",
        name: "add:north",
      },
      sku: this.sku,
      itemCondition: "NewCondition",
      manufacturer: this.manufacturer || "add:north",
      model: this.productName,
      productID: this.productId,
      offers: {
        "@type": "Offer",
        url: `https://addnorth.${process.env.VUE_APP_DOMAIN}${this.productLink}`,
        priceCurrency: this.#store.state.store.currency,
        price: this.msrp / 100,
        priceValidUntil: new Date(Date.now() + 180 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10),
        availability: "InStock",
      },
    };
    if (this.thumbnail && this.thumbnail.src) {
      jsonld.image = this.thumbnail.src;
    }
    if (this.gtin) {
      jsonld.gtin13 = this.gtin;
    }
    if (this.categories.length) {
      jsonld.category = this.categories[0].split(">").join("/");
    }
    if (this.attributes.color) jsonld.color = this.attributes.color;
    if (this.type === "filament") jsonld.material = this.productName;
    if (this.attributes.size) jsonld.size = `${this.attributes.size}g`;
    if (this.type === "filament") {
      jsonld.aggregateRating = {
        "@type": "AggregateRating",
        ratingValue: "5",
        reviewCount: "50",
      };
    }
    this.jsonld = jsonld;
  }

  #googleCartItem() {
    this.googleCart = {
      item_id: this.sku,
      item_name: this.productId,
      item_variant: this.name,
      price: this.price / 100,
      discount: Math.round(this.msrp - this.price) / 100,
      coupon: this.discount ? this.discount.name : null,
      quantity: this.quantity,
      ...this.categories.reduce((c, category, index) => {
        if (!category || index > 0) return c;
        category.split(">").forEach((str, i) => {
          c[`item_category${i > 0 ? i + 1 : ""}`] = str;
        });
        return c;
      }, {}),
    };
  }

  googleItem(item_list_name = null, item_list_id = null, index = 0) {
    return {
      item_id: this.sku,
      item_name: this.productId,
      item_variant: this.name,
      price: this.price / 100,
      discount: Math.round(this.msrp - this.price) / 100,
      coupon: this.discount ? this.discount.name : null,
      quantity: this.quantity,
      item_list_name,
      item_list_id,
      index,
      ...this.categories.reduce((c, category, index) => {
        if (!category || index > 0) return c;
        category.split(">").forEach((str, i) => {
          c[`item_category${i > 0 ? i + 1 : ""}`] = str;
        });
        return c;
      }, {}),
    };
  }

  toCart() {
    return {
      id: this.id,
      sku: this.sku,
      quantity: this.#quantity,
      subTotal: this.subTotal,
      totalDiscount: this.totalDiscount,
      vat: this.vat,
    };
  }
}
